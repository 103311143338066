import React, { useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

// On click, the user should indeed be sent to that specific member page

const UnitsWrapper = styled.svg`
  mix-blend-mode: multiply;
  & > g {
    transition: 0.5s;
    background-color: var(--white);
    &:not(#Unclickable):hover,
    &.locationSelected {
      cursor: pointer;
      & > path {
        opacity: 1;
        fill: var(--green);
      }
    }
  }
`;

const MapSVG = ({
  focusedLocation,
  showCommunityMember,
  handleSetCurrentType,
}) => {
  const locationQuery = useStaticQuery(graphql`
    query {
      allCraftMaplocationDefaultEntry {
        nodes {
          id
          locationName
          locationId
          locationPath
          title
          allowClick
        }
      }
    }
  `);

  const locationsOfClickable =
    locationQuery.allCraftMaplocationDefaultEntry.nodes?.filter(
      (node) => node?.allowClick
    );
  const locationsOfUnClickable =
    locationQuery.allCraftMaplocationDefaultEntry.nodes?.filter(
      (node) => !node?.allowClick
    );

  useEffect(() => {
    if (focusedLocation && focusedLocation !== "Unclickable") {
      handleChangeLocation(focusedLocation);
    }
  }, [focusedLocation]);

  const handleChangeLocation = (locationTitle) => {
    let allLocations = document.getElementsByClassName("locationSelected");
    let _allLocations = [...allLocations];
    _allLocations &&
      _allLocations?.map((item) => {
        item.classList.remove("locationSelected");
      });
    let element = document.getElementById(locationTitle);
    if (element) {
      element.classList.add("locationSelected");
      handleSetCurrentType(locationTitle);
    }
  };

  const formatTitle = (title) => {
    let _title = "";
    title.split("_").forEach((item) => {
      _title += item + " ";
    });
    return _title;
  };

  const parse = require("html-react-parser");
  const locationsElementClickable = locationsOfClickable.map(
    (node) =>
      node.locationPath && (
        <g
          onMouseMove={(e) => {
            let tooltip = document.getElementById("tooltip");
            tooltip.innerText = formatTitle(node.title);
            tooltip.classList.add("active");
            tooltip.style.left = `${e.pageX - 85}px`;
            tooltip.style.top = `${e.pageY - 330}px`;
          }}
          onMouseLeave={() => {
            let tooltip = document.getElementById("tooltip");
            tooltip.classList.remove("active");
          }}
          onClick={(e) => {
            handleChangeLocation(node?.title);
          }}
          key={node.id}
          id={node.title}
        >
          {parse(node.locationPath)}
        </g>
      )
  );
  const locationsElementUnClickable = locationsOfUnClickable.map(
    (node) =>
      node.locationPath && (
        <g key={node.id} id={node.title}>
          {parse(node.locationPath)}
        </g>
      )
  );

  return (
    <UnitsWrapper
      id="Units-Map"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1440 865.56"
      enable-background="new 0 0 1440 865.56"
    >
      {locationsElementUnClickable}
      {locationsElementClickable}
    </UnitsWrapper>
  );
};

export default MapSVG;
