import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ScheduleHero from "../../components/ScheduleHero";
import ScheduleMapToggle from "../../components/ScheduleMapToggle";
import {
  EventDiv,
  ScheduleDiv,
} from "../../components/ScheduleStyles/elements";
import Map from "../../components/Map";
import { Location } from "@reach/router";
import { graphql, navigate, useStaticQuery } from "gatsby";
import NotFoundEvent from "../../components/NotFoundEvent";
import EventList from "../../components/EventList";

const ScheduleMapPage = () => {
  const scheduleQuery = useStaticQuery(graphql`
    {
      events: allCraftQafEventsQafEventEntry {
        nodes {
          title
          slug
          subtitle
          eventShortDescription
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 4000, fit: COVER, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          eventType
          eventStartDateTime
          eventEndDateTime
          qafEventSubTheme
          qafEventLocation {
            title
            ... on Craft_maplocation_default_Entry {
              locationId
              allowClick
              title
              locationName
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const events = scheduleQuery?.events?.nodes?.filter((item) => {
    const thisDate = new Date(
      item.eventEndDateTime || item.eventStartDateTime || null
    );
    return thisDate >= now;
  });

  const [currentType, setCurrentType] = useState("");
  const [eventList, setEventList] = useState([]);
  const [theme, setTheme] = useState("");

  useEffect(() => {
    const _eventList = events.filter((item) => {
      let flag = false;
      item?.qafEventLocation.forEach((location) => {
        if (location?.title === currentType) {
          flag = true;
        }
      });
      return flag;
    });
    setEventList(_eventList);
  }, [currentType]);

  const formatTitle = (title) => {
    let _title = "";
    title.split("_").forEach((item) => {
      _title += item + " ";
    });
    return _title;
  };

  return (
    <Location>
      {({ location }) => (
        <Layout
          backgroundColor={"var(--black)"}
          skipHeader
          headerBlack={true}
          //   footerColor={"var(--red)"}
          //   title="Alserkal Avenue | A Community for
          // Contemporary Art & Homegrown Concepts"
          //   SEODescription="If you want dynamic cultural experiences, from
          // contemporary art and photography to spinning and yoga
          // classes, discover Alserkal Avenue today."
        >
          <ScheduleDiv>
            <ScheduleHero topLine="Programme" />
            <ScheduleMapToggle isMap />
            <Map
              useFakeMap={false}
              handleSetCurrentType={(type) => {
                setCurrentType(type);
                document
                  .getElementById("map-events")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              goToLocation={
                location.search.indexOf("?location=") > -1
                  ? decodeURI(location.search.split("?location=")[1])
                  : ""
              }
              showCommunityMember={(e) => {
                navigate(`/community/${e}`);
              }}
            />
            <EventDiv id="map-events">
              {currentType && (
                <>
                  <p>
                    <span>{formatTitle(currentType)}</span>
                  </p>
                  <EventList events={eventList} theme={theme} />
                  {eventList.length === 0 && <NotFoundEvent />}
                </>
              )}
            </EventDiv>
          </ScheduleDiv>
        </Layout>
      )}
    </Location>
  );
};

export default ScheduleMapPage;
